<template>
  <div class="center">
    <div class="search">
      <div class="ing pointer" @click="switchto">
        <div :style="{ color: isaction ? '#44A7FC' : '#000' }">进行中</div>
        <div :class="isaction ? 'action' : 'notaction'"></div>
      </div>
      <div class="ed pointer" @click="switchto">
        <div :style="{ color: isaction ? '#000' : '#44A7FC' }">其他</div>
        <div :class="isaction ? 'notaction' : 'action'"></div>
      </div>
    </div>
    <div class="list">
      <div :key="item.orderNo" class="table" v-for="item in tableDate">
        <div class="top">
          <div style="color: #333333">
            订单号：{{ item.orderNo }} &nbsp;&nbsp;|&nbsp;&nbsp; 下单人：{{
              item.consumerName
            }}
            &nbsp;&nbsp; {{ item.consumerTelephone }}
          </div>
          <div>
            <CountDown
              v-if="item.otherOrderStatus == 0"
              style="color: red; margin-right: 10px"
              @getList="getList"
              :endDate="`${item.timestamp}`"
            ></CountDown>
            <i class="hy-icon-shijian1"></i> {{ item.createTime }}
            <span class="status">{{
              otherOrderStatus[item.otherOrderStatus]
            }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="left pointer"  @click="todetail(item.serviceOrderId)">
            <div class="img">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.mainGraph"
              />
            </div>
            <div class="text">
              <div class="first f-hide" style="width:400px">{{ item.name }}</div>
              <div class="two">服务类型：{{ item.serviceType }}</div>
            </div>
          </div>
          <div class="middle">
            <span class="one">价格： </span><span v-if="item.goodsPrice" class="two">¥</span
            ><span class="three">{{ item.goodsPrice||'面议' }}</span>
          </div>
          <div class="right">
            <el-button
              v-if="item.otherOrderStatus == 1||item.otherOrderStatus == 4"
              type="primary"
              plain
              size="mini"
              @click="changestatus(4, item.serviceOrderId)"
              >确认服务完成</el-button
            >
            <el-button
              v-if="item.otherOrderStatus == 0||item.otherOrderStatus == 1"
              type="primary"
              plain
              style="width: 104px"
              size="mini"
              @click="toaplay(item)"
              >去支付</el-button
            >
            <el-button
              v-if="item.otherOrderStatus == 0"
              type="primary"
              plain
              style="width: 104px"
              size="mini"
              @click="changestatus(5, item.serviceOrderId)"
              >取消订单</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="allpage">
      <el-pagination
        background
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <keyworder
      :isShow.sync="passWordIsShow"
      :row="row"
      :balance="balance"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @submit="submit"
    />
  </div>
</template>

<script>
import { listServiceOrder, dealServiceOrder, applymenoy } from "@/api/home";
import CountDown from "@/components/datedowns.vue";
const { baseUrl } = require("@/config/index.js");
import * as types from "@/store/action-types";
import md5 from "js-md5";
import Vue from "vue";
import { getAccountBalanceByParam } from "@/api/Accountopening";
export default {
  components: {
    CountDown,
  },
  data() {
    return {
      balance:[],
      otherOrderStatus: [
        "待付款",
        "已付款",
        "已完成",
        "已取消",
        "对方已确认",
        "等待对方确认",
      ],
      tableDate: [],
      isaction: true,
      total: 0,
      formInline: {
        consumerId: "",
        dictId: "507",
        pageSize: 3,
        orderStatus: 1,
        pageNum: 1,
      },
      row:{},
      passWordIsShow:false
    };
  },

  mounted() {
	this.formInline.consumerId = Vue.ls.get(types.enterpriseId);
    this.getList();
  },
  methods: {
    getbalance(row) {
      let config = {
        goodsId: row.serviceGoodId,
        searchType: 2,
      };
      console.log(config)

      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balance = res.data;
          // this.centerDialogVisible = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    cancellationOrder(row) {

    },
    toaplay(row) {
      if (!this.beforhandle(['personalCenter:pstorageOrder:ongoing:topay'])) return;
      this.row = row
      this.getbalance(row)
      this.passWordIsShow = true 
    },
    submit(obj) {
      applymenoy({
        serviceOrderId: this.row.serviceOrderId,
        ...obj,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success("支付成功");
          this.getList();
        }else{
          this.$message.error(res.message);
        }
      });
    },
    async getList() {
      const res = await listServiceOrder(this.formInline);
      if (res.code == 0) {
        this.tableDate = res.data.list.map((item) => {
          item.serviceOrderVO.createTime =
            item.serviceOrderVO.createTime.replace("T", " ");
          return {
            ...item.serviceOrderVO,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              item.serviceOrderVO.mainGraph,
            timestamp:this.datacomponent(item.serviceOrderVO.createTime),
          };
        });
        console.log(this.tableDate);
        this.total = res.data.total;
      }
    },
    datacomponent(date) {
      let createTime = new Date(date).getTime() + 30*60*1000;
      let timestamp = new Date().getTime();
      return createTime - timestamp;
    },
    changestatus(operateType, serviceOrderId) {
      
      if (!this.beforhandle(['','','','','personalCenter:pstorageOrder:ongoing:confirm','personalCenter:pstorageOrder:ongoing:cancel'][operateType])) return;
      const status = [
        "",
        "确认接单",
        "确认拒绝接单",
        "确认服务完成",
        "确认服务完成",
        "确认取消订单"
      ];
      this.$confirm(status[operateType], "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zzy-confirm",
      })
        .then(() => {
          dealServiceOrder({ serviceOrderId, operateType }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    todetail(id) {
      if (!this.beforhandle(['personalCenter:pstorageOrder:ongoing:detail','personalCenter:pstorageOrder:other:detail'])) return;
      this.$router.push({
        path: "/uswarehousedatail",
        query: {
          id,
        },
      });
    },
    handleCurrentChange(value) {
      this.formInline.pageNum = value;
      this.getList();
    },
    switchto() {
      this.isaction = !this.isaction;
      if (this.isaction) {
        this.formInline.orderStatus = 1;
      } else {
        this.formInline.orderStatus = 2;
      }
      this.formInline.pageNum = 1;
      this.getList();
    },
    addfrom() {
      this.form.domains.push({
        valuesss: "",
        key: Date.now(),
      });
    },
    delfrom(delfrom) {
      let index = this.form.domains.indexOf(delfrom);
      if (index !== -1) {
        this.form.domains.splice(index, 1);
      }
    },
    handleClose() {
      dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
.zzy-confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
<style lang="scss">
.zyq_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    width: 401px;
  }
  .el-input {
    width: 211px;
  }
  .dialog_center {
    display: flex;
    justify-content: center;
    height: 300px;
    overflow-y: auto;
  }
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    .action {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 2px;
    }
    .notaction {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      border-radius: 2px;
    }
    .ing {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }
    .ed {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
      border-left: 1px solid #e5e5e5;
    }
  }
  .list {
    padding: 16px;
    padding-bottom: 0px;
    .table {
      height: 162px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      margin-bottom: 16px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        padding: 0 16px;
        border-bottom: 1px dashed #e5e5e5;
        color: #999999;
        .status {
          padding-left: 41px;
          color: #0a9d41;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 118px;
        padding: 0 8px;
      }
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
          overflow: hidden;
        }
        .text {
          margin-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #384048;
            margin-top: 4px;
            margin-bottom: 20px;
          }
          .two {
            height: 14px;
            line-height: 14px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 4px;
          }
        }
      }
      .middle {
        .one {
          font-size: 12px;
          color: #6d6b6b;
        }
        .two {
          font-size: 16px;
          color: #d0111a;
        }
        .three {
          padding-left: 4px;
          font-size: 28px;
          font-weight: 600;
          color: #d0111a;
        }
      }
      .right {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-end;
        height: 80px;
        padding-right: 17px;
        min-width: 120px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
    }
  }
  .allpage {
    text-align: right;
    margin-bottom: 20px;
    margin-right: 6px;
  }
}
</style>>